<template>
    <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
        <div class="flipper">
            <div :class="[ (side_a) ? 'front' : 'back']">
                <q-card class="my-card shadow-10">
                    <q-card-section class="q-pa-xs card-section-picture row q-gutter-none">
                        <img :src="picture" class="col-2" />
                        <div class="text-h6 col" v-html="title"></div>
                    </q-card-section>
                    <q-card-section class="card-section-description">
                        <div class="text-h6 text-primary" style="font-family: 'Comic Sans MS', cursive, sans-serif;" v-html="description" />
                    </q-card-section>
                    <q-card-actions v-if="url || more" class="row">
                        <div class="col-4"><q-btn v-if="more" flat class="openUrl" @click="flip()">MORE</q-btn></div>
                        <div class="col-8" style="text-align:right;"><a v-if="url" :href="url" target="_blank" class="openUrl">OPEN DEMO IN NEW TAB</a></div>
                    </q-card-actions>
                </q-card>
            </div>
            <div :class="[ (!side_a) ? 'front' : 'back']">
                <q-card class="my-card shadow-10">
                    <q-card-section class="q-pa-xs card-section-title row q-gutter-none">
                        <div class="text-h6 col" v-html="title"></div>
                    </q-card-section>
                    <q-card-section class="card-section-more">
                        <div class="text-subtitle2" v-html="more" />
                    </q-card-section>
                    <q-card-actions v-if="url || more" class="row">
                        <div class="col-4"><q-btn flat class="openUrl" @click="flip()">LESS</q-btn></div>
                        <div class="col-8" style="text-align:right;"><a v-if="url" :href="url" target="_blank" class="openUrl">OPEN DEMO IN NEW TAB</a></div>
                    </q-card-actions>
                </q-card>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
    name: "Flipcard",
    props: ['picture', 'title', 'description', 'url', 'more'],
    data(){
        return{
            side_a: true,
        };
    },
    methods: {
        flip(){
            this.side_a = !this.side_a;
        },
    },
})
</script>

<style scoped lang="less">
/* entire container, keeps perspective */
.flip-container {
	perspective: 1000px;
}

.flip-container, .front, .back {
	width: 300px;
	height: 300px;
}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;

	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
    /* border: 1px solid black; */
    transition: all .5s ease-in-out;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
    /* background-color: lightblue; */
}

/* back, initially hidden pane */
.back {
	transform: rotateY(180deg);
    /* background-color: lightgreen; */
}



.my-card {
    max-width: 300px;
    min-width: 300px;
    height: 300px;
    color: #666;
    display: flex;
    flex-direction: column;
    .card-section-picture {
        flex:0 0 80px;
        text-align: center;
        overflow:hidden;
        img {
            height:100%;
            width:auto;
        }
    }
    .card-section-title {
        flex:0 0 40px;
        text-align: center;
        overflow:hidden;
        img {
            height:100%;
            width:auto;
        }
    }
    .card-section-description {
        flex: 0 0 170px;
        background-color:#f0f0f0;
        border-top: 1px solid DarkGray;
        border-bottom: 1px solid DarkGray;
        .text-h6 {
            font-weight: bold;
        }
    }
    .card-section-more {
        flex: 0 0 210px;
        overflow-y: auto;
        background-color:#f0f0f0;
        border-top: 1px solid DarkGray;
        border-bottom: 1px solid DarkGray;
    }
    .q-card__actions {
        flex:0 0 36px;
        .openUrl {
            text-decoration: none;
            color: #777;
            font-weight: bolder;
            transition: all .5s ease-in-out;
            &:hover {
                color: #07f;
            }
        }
    }
}
</style>
